import React, { Component } from "react";

const widths = 128;
const styles = {
  leftImage: {
    marginLeft: "10%",
    maxHeight: "50px",
  },
  image: {
    marginRight: "3%",
    minWidth: "0px",
    minHeight: "0px",
    objectFit: "contain",
  },
  headerRight: {
    position: "absolute",
    right: "10%",
  },

  mainHeader: {
    display: "flex",
    width: "100%",
    padding: "30px 0px",
  },
};
export class Header2 extends Component {
  static displayName = Header2.name;

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { width } = this.state;
    const { color, logo, title } = this.props;
    return (
      <div
        style={{
          ...styles.mainHeader,
          ...(width > 768 ? {} : { justifyContent: "center" }),
        }}
      >
        <a
          href="/"
          style={{
            ...styles.leftImage,
            ...styles.image,
            width: widths,
            ...(width > 768 ? {} : { marginLeft: "0px" }),
          }}
        >
          <img alt="" src={logo} />
        </a>
        <div style={{ paddingLeft: "1%", borderLeft: "solid 1px" + color }}>
          <p
            style={{
              marginBottom: "0px",
              fontSize: "28px",
              fontWeight: "lighter",
              color: color,
            }}
          >
            {title}
          </p>
        </div>
        {/*<img  style={{...(width> 768? styles.headerRight:{}), width:tsWidth}} alt="" src="/resources/ts-logo.svg" />*/}
      </div>
    );
  }
}
