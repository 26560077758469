import React, { Component } from 'react';


const styles = {
    footer:{
        backgroundColor: "#DDDDDD",
        textAlign: "center"
    },
    content:{
        padding: "35px",
        color:"#2e2d29",
        fontSize: "12px",
        fontWeight: "lighter",
        marginBottom: "0px"
    },
    text:{
        color: "inherit"
    }
}

export class Footer extends Component {
  static displayName = Footer.name;

  render () {
    return (
        <div style={styles.footer}>
            <p style={styles.content}>©2020 Stanford Health Care. All Rights Reserved.  |  <a style={styles.text} href="mailto:TransportationServices@stanfordhealthcare.org">TransportationServices@stanfordhealthcare.org</a>  |  650.736.8000</p>
        </div>
    );
  }
}
