import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Img } from "../atoms/Img";
import { MapBox } from "../atoms/MapBox";
import { ParkingItem } from "../atoms/ParkingItem";
import { TDMCard } from "../atoms/TDMCard";
import { BlockGrid } from "../atoms/BlockGrid";
import { insertIntoArray } from "../util";
import { Alert } from "../atoms/Alert/Alert";
import { Radio } from "../atoms/Radio";
import { Container, Row, Col, Button, FormGroup } from "reactstrap";
import { isEmpty, isNil, equals } from "ramda";
import Select from "react-dropdown-select";
import { fridge } from "../utils/FridgeClient";

const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

const styles = {
  mainSection: {
    margin: "36px 0px",
  },
  fontSource: { fontFamily: "Source Sans Pro" },
  fontMyriad: { fontFamily: "Myriad Pro" },
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  center: { textAlign: "center" },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "0px",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#A90533",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    boarderColor: "#A90533",
    fontWeight: "lighter",
    width: "100%",
  },
  parkingHeader: {
    backgroundColor: "#A70C36",
    height: "3em",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "3em",
  },
  parkingText: {
    margin: "0px",
    fontWeight: "bold",
  },
  parkingMain: {
    paddingTop: "1em",
  },
  parkingMsg: {
    color: "#585754",
    fontSize: "14px",
  },
  parkingBuilding: {
    position: "relative",
    top: "-10px",
    textTransform: "uppercase",
  },
  spacerBottom: {
    marginBottom: "16px",
  },
  spacerTop: {
    marginTop: "36px",
  },
  parkingOptions: {
    maxHeight: "32em",
    minHeight: "30em",
    overflowY: "scroll",
  },
  noPadding: {
    padding: "0px",
  },
  inputsSection: {
    backgroundColor: "#F3F3F3",
  },
};

const calcBounds = (lots) => {
  const lats = lots.map((l) => parseFloat(l.lat));
  const longs = lots.map((l) => parseFloat(l.long));

  const n = Math.max(...lats);
  const s = Math.min(...lats);
  const e = Math.max(...longs);
  const w = Math.min(...longs);

  return [
    [e, n],
    [w, s],
  ];
};

/* const clearIcon = ({ props, state, methods }) => (
  <img
    width="20"
    src="/resources/times-circle-o.svg"
    onClick={() => methods.clearAll()}
  />
); */

//lot=>lot.employee_shift.some(equals(shift))
export class Staff extends Component {
  static displayName = Staff.name;

  constructor() {
    super();
    this.state = {
      building: "",
      buildings: [],
      crrBuilding: null,
      headline: "",
      altParkingHeadline: "",
      tdmCards: null,
      headerImage: null,
      shift: "Day Shift",
    };

    this.handleChange = this.handleChange.bind(this);
    this.isErrored = this.isErrored.bind(this);
  }

  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }

  async componentDidMount() {
    try {
      const [json, pageJson, permits] = await Promise.all([
        fridge.get("/content/buildings"),
        fridge.get("/content/staff_parking"),
        fridge.get("/content/permit_links"),
      ]);
      this.isErrored(json);
      this.isErrored(pageJson);
      this.isErrored(permits);

      this.setState({
        buildings: json,
        crrBuilding: json.find((b) => b.building_name === "500 Pasteur"),
        headline: pageJson.parking_headline_text,
        altParkingHeadline: pageJson.alt_parking_headline_text,
        defaultBuilding: json.find((b) => b.building_name === "500 Pasteur"),
        tdmCards: pageJson.tdm_cards,
        headerImage: pageJson.header_image,
        page: pageJson,
        permits: permits,
      });
    } catch (err) {
      this.isErrored({ error: { code: 500, message: "Unexpected error" } });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const {
      defaultBuilding,
      buildings,
      crrBuilding,
      headline,
      altParkingHeadline,
      tdmCards,
      headerImage,
      shift,
      permits,
      page,
    } = this.state;

    const lots = crrBuilding
      ? crrBuilding.associated_parking.filter(
          (lot) => lot.employee_shift && lot.employee_shift.includes(shift)
        )
      : null;
    const cards = tdmCards
      ? tdmCards.map((tdm, key) => (
          <Col md={6} lg={4} key={key}>
            <TDMCard card={tdm} />
          </Col>
        ))
      : null;

    return buildings && headerImage ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <Hero img={headerImage ? headerImage[0].url : null} />
        <SubHeader actives={{ parking: true }} />

        <Container style={{ marginBottom: "32px" }}>
          <Row>
            <Col style={{ marginTop: "32px" }} fluid></Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={3} fluid>
              <Img
                style={{
                  width: "250px",
                  height: "250px",
                  border: "1px solid #E5E5E5",
                }}
                src={
                  isNilOrEmpty(page.download_section_map_image)
                    ? ""
                    : page.download_section_map_image[0].url
                }
              />
            </Col>

            <Col
              md={6}
              lg={6}
              xl={8}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              fluid
            >
              <p style={{ ...styles.mainCopy }}>
                {page.download_section_title}
              </p>
              <Button
                href={
                  isNilOrEmpty(page.parking_pdf) ? "#" : page.parking_pdf[0].url
                }
                color="primary"
                target="_blank"
                style={{
                  width: "75%",
                  lineHeight: "46px",
                  height: "4em",
                  ...styles.cardButton,
                  fontWeight: "normal",
                }}
              >
                <img
                  style={{ position: "relative", left: "-13px" }}
                  src="/resources/download.svg"
                  alt=""
                />
                {page.download_button_text}
              </Button>
            </Col>
          </Row>
        </Container>

        <div style={styles.inputsSection}>
          <Container style={{ marginBottom: "32px" }}>
            <Row>
              <Col style={{ marginTop: "32px" }} fluid>
                <p style={{ ...styles.mainCopy, ...styles.center }}>
                  {headline}
                </p>

                <Row style={{ marginTop: "32px" }}>
                  <Col sm="12" md={{ size: 8, offset: 2 }}>
                    <Select
                      options={buildings ? buildings : []}
                      onChange={(b) => {
                        if (isEmpty(b))
                          this.setState({
                            building: defaultBuilding.building_name,
                            crrBuilding: defaultBuilding,
                          });
                        else
                          this.setState({
                            building: b[0].building_name,
                            crrBuilding: b[0],
                          });
                      }}
                      labelField={"building_name"}
                      searchBy={"building_name"}
                      multi={false}
                      clearable={true}
                      dropdownHandle={false}
                      style={{ height: "40px", background: "#fff" }}
                      placeholder={`Enter your work location (i.e "Lucile Packard Children's Hospital")`}
                      //dropdownHandle={true}
                      //clearRenderer={clearIcon}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "16px",
                      }}
                    >
                      <FormGroup style={{ marginBottom: "0px" }}>
                        <Radio
                          style={{
                            ...styles.fontSource,
                            ...{ fontSize: "18px" },
                          }}
                          label="Day Shift"
                          value="Day Shift"
                          name="shift"
                          onClick={this.handleChange}
                          checked={equals(shift, "Day Shift")}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: "0px" }}>
                        <Radio
                          style={{
                            ...styles.fontSource,
                            ...{ fontSize: "18px" },
                          }}
                          label="Swing Shift"
                          value="Swing Shift"
                          name="shift"
                          onClick={this.handleChange}
                          checked={equals(shift, "Swing Shift")}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: "0px" }}>
                        <Radio
                          style={{
                            ...styles.fontSource,
                            ...{ fontSize: "18px" },
                          }}
                          label="Night Shift"
                          value="Night Shift"
                          name="shift"
                          onClick={this.handleChange}
                          checked={equals(shift, "Night Shift")}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col sm="0" md="7" style={styles.noPadding}>
              {crrBuilding ? (
                <MapBox
                  ref={(e) => {
                    this.map = e;
                  }}
                  center={[crrBuilding.long, crrBuilding.lat]}
                  parking={lots}
                  fitBounds={calcBounds([...lots, crrBuilding])}
                  shift={shift}
                />
              ) : null}
            </Col>
            <Col sm="12" md="5" style={styles.noPadding}>
              <div style={styles.parkingHeader}>
                <p style={styles.parkingText}>PARKING OPTIONS</p>
              </div>
              <div style={styles.parkingMain} class="parking-options">
                <div style={styles.spacerBottom}>
                  <span style={{ ...styles.parkingMsg, ...styles.italic }}>
                    Showing options for:
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      ...styles.parkingMsg,
                      ...styles.bold,
                      ...styles.fontSource,
                      ...styles.parkingBuilding,
                    }}
                  >
                    {crrBuilding ? crrBuilding.building_name : "Nothing"}
                  </span>
                </div>
                <div
                  style={styles.parkingOptions}
                  ref={(e) => {
                    this.outterParkingList = e;
                  }}
                >
                  {crrBuilding
                    ? insertIntoArray(
                        lots.map((b) => (
                          <ParkingItem
                            shift={shift}
                            parking={b}
                            permits={permits}
                            buildingAddress={crrBuilding.address}
                          />
                        )),
                        <hr />
                      )
                    : null}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <p
                style={{
                  ...styles.mainCopy,
                  ...styles.bold,
                  ...styles.center,
                  ...styles.spacerTop,
                }}
              >
                {altParkingHeadline}
              </p>
            </Col>
          </Row>

          {cards ? <BlockGrid list={cards} /> : null}
        </Container>

        <Footer />
      </div>
    ) : null;
  }
}
