import React, { Component } from 'react';

export class Img extends Component {
  static displayName = Img.name;

  render () {
    const {src, style} = this.props;
    const imgStyle = {
        backgroundImage: `url("${src}")`, 
        backgroundColor: "#cccccc",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        ...style
    }
    return (
        <div style={imgStyle}></div> 
    );
  }
}
