import React, { Component } from "react";
import * as R from "ramda";
import { Container, Row } from "reactstrap";

const styles = {
  row: {
    margin: "15px 0px",
  },
};

const isNilOrEmpty = (x) => R.isEmpty(x) || R.isNil(x);

export class BlockGrid extends Component {
  static displayName = BlockGrid.name;
  constructor() {
    super();
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { width } = this.state;
    const { list, containerStyle, rowStyle } = this.props;
    return isNilOrEmpty(list) ? (
      <div></div>
    ) : (
      <Container style={containerStyle ? containerStyle : null}>
        {R.pipe(
          (x) => console.log(`${width} : }`) || x,
          R.splitEvery(width <= 992 ? 2 : 3),
          (x) => console.log(x) || x,
          R.map((a) => <Row style={rowStyle ? rowStyle : styles.row}>{a}</Row>)
        )(list)}
      </Container>
    );
  }
}
