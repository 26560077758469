import React, { Component } from "react";
import { Header2 } from "../atoms/Header2";
import { Hero } from "../atoms/Hero";
import { SubHeader2 } from "../atoms/SubHeader2";
import { Alert } from "../atoms/Alert/Alert";
import { BlockGrid } from "../atoms/BlockGrid";
import { Img } from "../atoms/Img";
import { Footer } from "../atoms/Footer";
import {
  Container,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Jumbotron,
  Input,
} from "reactstrap";
import { isEmpty, isNil } from "ramda";
import { AltCard } from "../atoms/AltCard";
import ReactHtmlParser from "react-html-parser";
import { fridge } from "../utils/FridgeClient";

const colors = {
  Blue: "#279AB9",
  "Stanford Red": "#A90533",
  "Light Grey": "#DDDDDD",
};

const styles = {
  searchWrapper: {
    background: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainSection: {
    margin: "36px 0px 0px",
  },
  altHeader: {
    color: "#A80534",
  },
  center: { textAlign: "center" },
  cardSection: {
    margin: "16px 0px",
  },
  mainImg: {
    width: "100%",
  },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    width: "100%",
    fontSize: "12px",
    color: "white",
  },
  onOffSite: {
    padding: "4px 24px",
    borderRadius: 0,
    fontWeight: "bold",
  },
};
const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

/* const renderButtons = (buttons) => (
  <div className="mt-auto">
    {buttons.map((b) =>
      b.type === "pdf_button" ? (
        <Button
          href={
            isNilOrEmpty(b.pdf_or_document_file)
              ? "#"
              : b.pdf_or_document_file[0].url
          }
          style={{
            ...styles.cardButton,
            backgroundColor: colors[b.button_color],
          }}
        >
          {b.button_text}
        </Button>
      ) : (
        <Button
          target={b.open_in_new_window ? "_blank" : "_self"}
          href={b.button_url}
          style={{ background: colors[b.button_color], ...styles.cardButton }}
        >
          {b.button_text}
        </Button>
      )
    )}
  </div>
); */

const MapCard = (props) => (
  <Card style={{ height: "100%" }}>
    {isNilOrEmpty(props.image) ? null : (
      <div style={styles.crop}>
        <Img src={props.image[0].url} style={{ height: "200px" }} />
      </div>
    )}
    <CardBody>
      <CardTitle style={styles.cardTitle}>{props.name}</CardTitle>
      <CardText style={{ height: "5em" }}>
        {ReactHtmlParser(props.text)}
      </CardText>
      <div
        style={{ position: "absolute", bottom: "22px", width: "85%" }}
        className="mt-auto"
      >
        <Button
          style={{ ...styles.cardButton, ...{ backgroundColor: props.color } }}
          href={props.pdf_flyer[0].url}
        >
          Continue
        </Button>
      </div>
    </CardBody>
  </Card>
);

const typeMap = {
  paragraph_heading: ({ heading, paragraph }) => (
    <div style={styles.mainSection}>
      <h4>{heading}</h4>
      {ReactHtmlParser(paragraph)}
    </div>
  ),
  paragraph: ({ paragraph }) => ReactHtmlParser(paragraph),
  image: ({ image, image_max_width: maxWidth }) => (
    <img
      style={{ maxWidth, ...styles.mainSection }}
      src={image[0].url}
      alt=""
    />
  ),
  alternate_section: ({ paragraph, section_heading }) => (
    <Jumbotron style={{ ...{ padding: "2em" }, ...styles.mainSection }}>
      <h4 style={styles.altHeader}>{section_heading}</h4>
      {ReactHtmlParser(paragraph)}
    </Jumbotron>
  ),
};

const sectionMapper = (section) => typeMap[section.type](section);

export class VisitorSHC extends Component {
  static displayName = VisitorSHC.name;

  constructor() {
    super();
    this.state = {
      page: null,
      searchTxt: "",
    };
  }

  async componentDidMount() {
    //const { type } = this.props;

    const pageJson = await fridge.get("/content/shc_visitor_page");

    if (pageJson.error) {
      this.props.onError(
        <p>
          {pageJson.error.code} {pageJson.error.message}
        </p>
      );
    }

    this.setState({ page: pageJson });
  }

  render() {
    const { page, searchTxt } = this.state;
    const entries = page
      ? [
          page.parking_updates_title,
          page.parking_information_title,
          page.parking_maps_and_resources_title,
          page.shuttles_and_transportation_title,
        ]
      : [];

    return page ? (
      <div>
        <Alert type="alert_banner_visitor" />
        <Header2
          color={colors["Stanford Red"]}
          logo="/resources/shc-logo.svg"
          title={page.page_title}
        />
        <Hero
          img={
            isNilOrEmpty(page.header_image) ? null : page.header_image[0].url
          }
        />
        <SubHeader2
          color={colors["Stanford Red"]}
          entries={entries}
          root="/SHCPatientVisitor"
        />

        <Container>
          <Row>
            <Col style={{ marginTop: "32px" }} fluid>
              <h3
                id={entries[0].replace(/ /g, "")}
                style={{
                  ...styles.center,
                  ...{ fontWeight: "bold" },
                  ...{ marginTop: "32px" },
                  ...{ textTransform: "uppercase" },
                }}
              >
                {isNilOrEmpty(page.parking_updates_title) ? null : entries[0]}
              </h3>
            </Col>
          </Row>
        </Container>

        {page ? (
          <Container style={{ marginBottom: "32px", marginTop: "32px" }}>
            <Row>
              <Col fluid>{page.parking_updates_content.map(sectionMapper)}</Col>
            </Row>
          </Container>
        ) : null}

        <Container>
          <Row>
            <Col style={{ marginTop: "32px", marginBottom: "32px" }} fluid>
              <hr style={{ borderColor: "#A90533" }} />

              <h3
                id={entries[1].replace(/ /g, "")}
                style={{
                  ...styles.center,
                  ...{ fontWeight: "bold" },
                  ...{ marginTop: "32px" },
                  ...{ textTransform: "uppercase" },
                }}
              >
                {isNilOrEmpty(page.parking_information_title)
                  ? null
                  : entries[1]}
              </h3>
            </Col>
          </Row>
        </Container>

        {
          <div style={styles.searchWrapper}>
            <Container>
              <Row>
                <Col
                  style={{
                    marginTop: "32px",
                    marginBottom: "32px",
                    padding: "0 64px",
                  }}
                  fluid
                >
                  <Input
                    type="search"
                    placeholder="search this section"
                    onChange={(e) =>
                      this.setState({ searchTxt: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Container>
          </div>
        }

        <Container style={{ marginBottom: "32px" }}>
          <Row>
            <Col fluid>
              <BlockGrid
                list={
                  isNilOrEmpty(page.parking_information_cards)
                    ? null
                    : page.parking_information_cards
                        .filter(
                          (x) =>
                            x.title
                              .toLowerCase()
                              .includes(searchTxt.toLowerCase()) ||
                            (
                              (x.description || "").toLowerCase() || ""
                            ).includes(searchTxt.toLowerCase())
                        )
                        .map((c) => (
                          <Col md={6} lg={4}>
                            <MapCard
                              announcement={c.id}
                              image={c.image}
                              name={c.title}
                              text={c.description}
                              pdf_flyer={c.pdf_file}
                              color={colors["Stanford Red"]}
                            />
                          </Col>
                        ))
                }
              />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col style={{ marginTop: "32px", marginBottom: "32px" }} fluid>
              <hr style={{ borderColor: "#A90533" }} />

              <h3
                id={entries[2].replace(/ /g, "")}
                style={{
                  ...styles.center,
                  ...{ fontWeight: "bold" },
                  ...{ marginTop: "32px" },
                  ...{ textTransform: "uppercase" },
                }}
              >
                {entries[2]}
              </h3>
            </Col>
          </Row>
        </Container>

        <Container style={{ marginBottom: "32px" }}>
          <Row>
            <Col fluid>
              <BlockGrid
                list={
                  isNilOrEmpty(page.parking_maps_and_resources_cards)
                    ? null
                    : page.parking_maps_and_resources_cards.map((c) => (
                        <Col md={6} lg={4}>
                          <MapCard
                            announcement={c.id}
                            image={c.image}
                            name={c.title}
                            text={c.description}
                            pdf_flyer={c.pdf_file}
                            color={colors["Stanford Red"]}
                          />
                        </Col>
                      ))
                }
              />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col style={{ marginTop: "32px", marginBottom: "32px" }} fluid>
              <hr style={{ borderColor: "#A90533" }} />
              <h3
                id={entries[3].replace(/ /g, "")}
                style={{
                  ...styles.center,
                  ...{ fontWeight: "bold" },
                  ...{ marginTop: "32px" },
                  ...{ textTransform: "uppercase" },
                }}
              >
                {isNilOrEmpty(page.parking_maps_and_resources_title)
                  ? null
                  : entries[3]}
              </h3>
            </Col>
          </Row>
        </Container>

        <Container style={{ marginBottom: "32px" }}>
          <Row>
            <Col fluid>
              <BlockGrid
                list={
                  isNilOrEmpty(page.shuttles_and_transportation_cards)
                    ? null
                    : page.shuttles_and_transportation_cards.map((a) => (
                        <AltCard
                          alt={{ ...a, ...{ color: colors["Stanford Red"] } }}
                        />
                      ))
                }
              />
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    ) : null;
  }
}
