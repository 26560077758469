import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import "./css/Error.css";
import { fridge } from "../utils/FridgeClient";

const styles = {
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "#A90533",
    width: "100%",
  },
  cardBody: {
    padding: "36px",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  rc: {
    borderRadius: "5px",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #9C9C9C",
  },
  inputSpace: {
    marginBottom: "1.5em",
  },
  mainSpace: {
    padding: "36px",
  },
};

export class Error extends Component {
  static displayName = Error.name;

  async componentDidMount() {
    const json = await fridge.get(
      "/content/redirect?source=" + window.location.pathname
    );

    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }

    if (json.length === 1) {
      console.log(json[0]);

      if (json[0].destination != null) {
        window.location = json[0].destination;
      } else if (json[0].pdf.file[0].url != null) {
        window.location = json[0].pdf.file[0].url;
      }
    } else this.setState({ loaded: true });
  }

  constructor(props) {
    super(props);
    //const { msg } = props;

    this.state = {};
  }

  render() {
    const { msg } = this.props;

    if (this.state.loaded) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1 }}>
            <Header />
            <SubHeader actives={{ contact: false }} />

            <div style={{ ...styles.main, ...styles.mainSpace }}>
              <div style={styles.main}>
                <p style={styles.cardTitle}>
                  WE'RE SORRY BUT SOMETHING WENT WRONG
                </p>
                <p style={styles.mainCopy}>
                  Please try refreshing the page, or use one of the links above.
                </p>
                <div>{msg}</div>
              </div>
            </div>
          </div>

          <div style={{ flexShrink: 0 }}>
            <Footer />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
