import React, { Component } from "react";
import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";
import { either, isNil } from "ramda";

const isNilorNan = either(isNil, Number.isNaN);

export class MapBox extends Component {
  static displayName = MapBox.name;
  constructor() {
    super();
    this.state = {
      accessToken: null,
      center: [0, 0],
      render: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.center[0] !== nextProps.center[0] ||
      this.props.center[1] !== nextProps.center[1] ||
      this.state.render ||
      this.props.shift !== nextProps.shift
    );
  }

  render() {
    const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const { center, parking, fitBounds } = this.props;
    const Map = accessToken ? ReactMapboxGl({ accessToken }) : null;
    if (Map) {
      return (
        <Map
          style={`mapbox://styles/mapbox/light-v10`}
          zoom={[17]}
          movingMethod="flyTo"
          ref={(e) => {
            this.map = e;
          }}
          fitBounds={fitBounds}
          fitBoundsOptions={{ padding: 100 }}
        >
          <MapBoxInner center={center} parking={parking} />
        </Map>
      );
    } else {
      return null;
    }
  }
}

class MapBoxInner extends Component {
  static displayName = MapBoxInner.name;
  constructor() {
    super();
    this.state = {
      popup: false,
    };
  }

  render() {
    const { popup } = this.state;
    const { center, parking } = this.props;
    return (
      <div>
        <Marker coordinates={center}>
          {" "}
          <img width="48px" src="/resources/map-pin-red.svg" alt="" />
        </Marker>

        {parking
          ? parking.map((p) =>
              isNilorNan(p.long) || isNilorNan(p.lat) ? null : (
                <Marker
                  coordinates={[p.long, p.lat]}
                  onMouseEnter={() => {
                    this.setState({
                      popup: {
                        center: [p.long, p.lat],
                        content: (
                          <p>
                            {p.name}
                            <br />
                            {p.address}
                          </p>
                        ),
                      },
                    });
                  }}
                  onMouseLeave={() => this.setState({ popup: false })}
                >
                  <img width="48px" src="/resources/map-pin-black.svg" alt="" />
                </Marker>
              )
            )
          : null}

        {popup ? (
          <Popup
            tipSize={5}
            coordinates={[popup.center[0], popup.center[1]]}
            anchor="bottom"
            offset={50}
          >
            {popup.content}
          </Popup>
        ) : null}
      </div>
    );
  }
}
