import React, { Component } from "react";
import {
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const styles = {
  subHeader: {
    backgroundColor: "#A70C36",
  },
  breadCrumbs: {
    padding: "7px 35px",
  },
  crumb: {
    fontSize: "16px",
    fontFamily: "Source Sans Pro",
    color: "white",
    fontWeight: "bold",
  },
  seperator: {
    margin: "0px 5px",
  },
  navLinks: {
    color: "white",
  },
  navItems: {
    borderLeft: "#8D0723 solid 1px",
    borderRight: "#8D0723 solid 1px",
    marginRight: "-1px",
    padding: "0.5em",
  },
  navItemLast: {
    borderRight: "#8D0723 solid 1px",
  },
  navBar: {
    justifyContent: "center",
    padding: "0em 1em",
  },
};

export class SubHeader extends Component {
  static displayName = SubHeader.name;
  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      open: false,
    };
  }
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  render() {
    const { actives } = this.props;
    const { dropdownOpen, open } = this.state;
    return (
      <div style={styles.subHeader}>
        <Navbar color="#A70C36" style={styles.navBar} expand="md">
          <NavbarToggler onClick={() => this.setState({ open: !open })} />
          <Collapse style={{ justifyContent: "center" }} isOpen={open} navbar>
            <Nav navbar>
              <NavItem style={styles.navItems} active={actives.home}>
                <NavLink style={styles.navLinks} href="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem style={styles.navItems} active={actives.parking}>
                <NavLink style={styles.navLinks} href="/Parking">
                  Staff Parking
                </NavLink>
              </NavItem>
              <NavItem style={styles.navItems} active={actives.shuttles}>
                <NavLink style={styles.navLinks} href="/Shuttles/">
                  Shuttles
                </NavLink>
              </NavItem>
              <NavItem style={styles.navItems} active={actives.alternatives}>
                <NavLink style={styles.navLinks} href="/Alternatives/">
                  Alternative Transportation Options
                </NavLink>
              </NavItem>
              <NavItem style={styles.navItems} active={actives.faqs}>
                <NavLink style={styles.navLinks} href="/Faq">
                  FAQs
                </NavLink>
              </NavItem>
              <NavItem style={{ ...styles.navItems, ...styles.subHeader }}>
                <Dropdown isOpen={dropdownOpen} style={styles.subHeader}>
                  <DropdownToggle
                    onClick={() =>
                      this.setState({ dropdownOpen: !dropdownOpen })
                    }
                    style={styles.navLinks}
                    onMouseEnter={this.onMouseEnter}
                  >
                    Quick Links
                  </DropdownToggle>
                  <DropdownMenu
                    style={{ ...styles.navLinks, ...styles.subHeader }}
                    onMouseLeave={this.onMouseLeave}
                  >
                    <DropdownItem style={styles.navItems}>
                      <NavLink style={styles.navLinks} href="/Announcements/">
                        Announcements
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem style={styles.navItems}>
                      <NavLink style={styles.navLinks} href="/COVID19/">
                        COVID-19 Updates
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem style={styles.navItems}>
                      <NavLink
                        style={styles.navLinks}
                        href="/mapsandresources/"
                      >
                        Maps and Resources
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem style={styles.navItems}>
                      <NavLink style={styles.navLinks} href="/newsletters/">
                        Newsletters
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem style={styles.navItems}>
                      <NavLink style={styles.navLinks} href="/newemployees/">
                        New Employees
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem style={styles.navItems}>
                      <NavLink
                        style={styles.navLinks}
                        href={localStorage.getItem("servicesCodes")}
                      >
                        Transportation Services Code
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>

              <NavItem style={styles.navItems} active={actives.contact}>
                <NavLink style={styles.navLinks} href="/Contact/">
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
