import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Img } from "../atoms/Img";
import { Footer } from "../atoms/Footer";
import { Row, Col, Container } from "reactstrap";
import { TDMCard } from "../atoms/TDMCard";
import { BlockGrid } from "../atoms/BlockGrid";
import { Alert } from "../atoms/Alert/Alert";
import { fridge } from "../utils/FridgeClient";

const styles = {
  mainSection: {
    margin: "36px 0px",
  },
  cardSection: {
    margin: "16px 0px",
  },
  mainImg: {
    width: "100%",
  },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    boarderColor: "#A90533",
  },
  onOffSite: {
    padding: "4px 24px",
    borderRadius: 0,
    fontWeight: "bold",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  rc: {
    borderRadius: "5px",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "36px",
  },
  preferred: {
    display: "flex",
    padding: "7px 15%",
    border: "solid #037A86 4px",
    borderRadius: "40px",
    color: "#037A86",
  },
  preferredImage: {
    position: "absolute",
    top: "-12%",
    left: "5%",
    width: "96px",
  },
  parkingImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12em",
  },
  parkingCell: {
    flex: 1,
    padding: "32px 1em",
    width: "220px",
  },
  parkingImage: {
    border: "solid 4px #037A86",
    height: "125px",
    width: "220px",
  },
  bad: {
    color: "#A70331",
    borderColor: "#A70331",
  },
};

export class Alternatives extends Component {
  static displayName = Alternatives.name;
  constructor() {
    super();
    this.state = { tdmCards: [] };
    this.isErrored = this.isErrored.bind(this);
  }

  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }

  async componentDidMount() {
    const json = await fridge.get("/content/tdm_cards");
    this.isErrored(json);

    const pageJson = await fridge.get("/content/alternative_transportation");
    this.isErrored(pageJson);

    this.setState({
      tdmCards: json,
      headerImage: pageJson.header_image,
      page: pageJson,
    });
  }

  render() {
    const { tdmCards, headerImage, page } = this.state;
    console.log(tdmCards);
    return tdmCards && headerImage && page ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <Hero img={headerImage ? headerImage[0].url : null} />
        <SubHeader actives={{ alternatives: true }} />

        <Container>
          <Row style={{ ...styles.mainSection, ...{ marginTop: "68px" } }}>
            <Col sm={12}>
              <img
                className="preferred-img"
                style={styles.preferredImage}
                src="/resources/check-circle.svg"
                alt=""
              />
              <div className="preferred-text">
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    ALTERNATIVE COMMUTER OPTIONS:{" "}
                  </span>{" "}
                  Preferred and easiest method.
                </span>
              </div>
              <div
                class="parking-image-container"
                style={styles.parkingImageContainer}
              >
                <div style={styles.parkingCell}>
                  <Img
                    src={page.tdm_photo_1[0].url}
                    style={styles.parkingImage}
                  />
                </div>
                <div style={styles.parkingCell}>
                  <Img
                    src={page.tdm_photo_2[0].url}
                    style={styles.parkingImage}
                  />
                </div>
                <div style={styles.parkingCell}>
                  <Img
                    src={page.tdm_photo_3[0].url}
                    style={styles.parkingImage}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row style={styles.mainSection}>
            <Col sm={12}>
              <img
                className="preferred-img"
                style={{ ...styles.preferredImage, ...styles.bad }}
                src="/resources/thumbs-down.svg"
                alt=""
              />
              <div className="preferred-text" style={{ ...styles.bad }}>
                <span>
                  <span style={{ fontWeight: "bold" }}>ON-SITE PARKING: </span>{" "}
                  Not a preferred option, try alternative commuter options
                  instead!
                </span>
              </div>
              <div
                className="parking-image-container"
                style={{ ...styles.parkingImageContainer, ...styles.bad }}
              >
                <div style={{ ...styles.parkingCell, ...styles.bad }}>
                  <Img
                    src={page.parking_photo_1[0].url}
                    style={{ ...styles.parkingImage, ...styles.bad }}
                  />
                </div>
                <div style={{ ...styles.parkingCell, ...styles.bad }}>
                  <Img
                    src={page.parking_photo_2[0].url}
                    style={{ ...styles.parkingImage, ...styles.bad }}
                  />
                </div>
                <div style={{ ...styles.parkingCell, ...styles.bad }}>
                  <Img
                    src={page.parking_photo_3[0].url}
                    style={{ ...styles.parkingImage, ...styles.bad }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div style={{ ...styles.main }}>
          <div>
            <p style={styles.cardTitle}>Alternative Transportation Options</p>
          </div>
        </div>

        {tdmCards ? (
          <BlockGrid
            list={tdmCards.map((tdm) => (
              <Col md={6} lg={4}>
                {" "}
                <TDMCard card={tdm} />{" "}
              </Col>
            ))}
          />
        ) : null}
        <Footer />
      </div>
    ) : null;
  }
}
