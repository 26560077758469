import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Img } from "../atoms/Img";
import { Alert } from "../atoms/Alert/Alert";
import {
  Row,
  Col,
  Container,
  Jumbotron,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import "./css/Announcement.css";
import { isEmpty, isNil } from "ramda";
import { BlockGrid } from "../atoms/BlockGrid";
import { fridge } from "../utils/FridgeClient";

const styles = {
  center: { textAlign: "center" },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "#A90533",
    width: "100%",
  },
  cardBody: {
    padding: "36px",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  rc: {
    borderRadius: "5px",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #9C9C9C",
  },
  inputSpace: {
    marginBottom: "1.5em",
  },
  mainSpace: {
    padding: "36px",
  },
};
const typeMap = {
  paragraph_heading: ({ heading, paragraph }) => (
    <div style={styles.mainSection}>
      <h4>{heading}</h4>
      {ReactHtmlParser(paragraph)}
    </div>
  ),
  paragraph: ({ paragraph }) => ReactHtmlParser(paragraph),
  image: ({ image, image_max_width: maxWidth }) => (
    <img
      style={{ maxWidth, ...styles.mainSection }}
      src={image[0].url}
      alt=""
    />
  ),
  alternate_section: ({ paragraph, section_heading }) => (
    <Jumbotron style={{ ...{ padding: "2em" }, ...styles.mainSection }}>
      <h4 style={styles.altHeader}>{section_heading}</h4>
      {ReactHtmlParser(paragraph)}
    </Jumbotron>
  ),
};

const colors = {
  Blue: "#279AB9",
  "Stanford Red": "#A90533",
  "Light Grey": "#DDDDDD",
};
const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

/* const renderButtons = (buttons) => (
  <div className="mt-auto">
    {buttons.map((b) =>
      b.type === "pdf_button" ? (
        <Button
          href={
            isNilOrEmpty(b.pdf_or_document_file)
              ? "#"
              : b.pdf_or_document_file[0].url
          }
          style={{
            ...styles.cardButton,
            backgroundColor: colors[b.button_color],
          }}
        >
          {b.button_text}
        </Button>
      ) : (
        <Button
          target={b.open_in_new_window ? "_blank" : "_self"}
          href={b.button_url}
          style={{ background: colors[b.button_color], ...styles.cardButton }}
        >
          {b.button_text}
        </Button>
      )
    )}
  </div>
); */

const MapCard = (props) => (
  <Card style={{ height: "100%" }}>
    {isNilOrEmpty(props.image) ? null : (
      <div style={styles.crop}>
        <Img src={props.image[0].url} style={{ height: "200px" }} />
      </div>
    )}
    <CardBody>
      <CardTitle style={styles.cardTitle}>{props.name}</CardTitle>
      <CardText style={{ height: "5em" }}> </CardText>
      <div
        style={{ position: "absolute", bottom: "22px", width: "85%" }}
        className="mt-auto"
      >
        <Button
          style={{ ...styles.cardButton, ...{ backgroundColor: props.color } }}
          href={props.file ? props.file[0].url : null}
        >
          Continue
        </Button>
      </div>
    </CardBody>
  </Card>
);

const sectionMapper = (section) => typeMap[section.type](section);

export class NewEmployee extends Component {
  static displayName = NewEmployee.name;
  constructor() {
    super();
    this.state = { page: null, header: null };
  }

  async componentDidMount() {
    //const urlParams = new URLSearchParams(window.location.search);
    //const announcementKey = urlParams.get('announcementKey');
    //console.log({announcementKey})

    const announcementsJson = await fridge.get(`/content/new_employees`);
    this.setState({
      page: announcementsJson,
      header:
        announcementsJson.header_image &&
        announcementsJson.header_image.length > 0
          ? announcementsJson.header_image
          : null,
    });
  }
  render() {
    const { page, header } = this.state;
    console.log({ page, header });
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Alert type="alert_banner_staff" />
        <div style={{ flex: 1 }}>
          <Header />
          {header ? <Hero img={header[0].url} /> : null}
          <SubHeader actives={{ contact: false }} />

          {page ? (
            <Container>
              <Row>
                <Col style={{ marginTop: "32px" }} fluid>
                  <h3 style={{ ...styles.center, ...{ fontWeight: "bold" } }}>
                    {page.page_title}
                  </h3>
                </Col>
              </Row>
            </Container>
          ) : null}

          {page ? (
            <div>
              <Container style={{ marginBottom: "32px", marginTop: "32px" }}>
                <Row>
                  <Col fluid>{page.content_area.map(sectionMapper)}</Col>
                </Row>
              </Container>

              <Container style={{ marginBottom: "32px" }}>
                <Row>
                  <Col fluid>
                    <BlockGrid
                      list={
                        isNilOrEmpty(page.employee_resources)
                          ? null
                          : page.employee_resources.map((c) => (
                              <Col md={6} lg={4}>
                                <MapCard
                                  announcement={c.id}
                                  image={c.image}
                                  name={c.name}
                                  text={c.description}
                                  file={c.file}
                                  color={colors["Stanford Red"]}
                                />
                              </Col>
                            ))
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          ) : null}
        </div>

        <div style={{ flexShrink: 0 }}>
          <Footer />
        </div>
      </div>
    );
  }
}
