import React, { Component } from "react";
import { FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import ReactHtmlParser from "react-html-parser";
import "./alert.css";
import { fridge } from "../../utils/FridgeClient";

const styles = {
  alert: {
    textAlign: "center",
    padding: "0 32px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  content: {
    padding: "18px",
    color: "white",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "0px",
    flex: 2,
    textAlign: "center",
  },
  clickable: {
    cursor: "pointer",
  },
};

const colors = {
  Blue: "#279AB9",
  Red: "#A90533",
  Yellow: "#EBAC13",
};

export class Alert extends Component {
  static displayName = Alert.name;

  constructor(args) {
    super(args);
    this.state = {
      showNotifcations: sessionStorage.getItem("showNotifcations") == null,
      alert: {},
    };
  }

  async componentDidMount() {
    const json = await fridge.get(`/content/${this.props.type}`);

    this.setState({ alert: json });
  }

  render() {
    const { showNotifcations, alert } = this.state;
    return showNotifcations && alert.enabled === true ? (
      <IconContext.Provider
        value={{ color: "white", className: "global-class-name" }}
      >
        <div
          className={`alert-${alert.color}`}
          style={{ ...styles.alert, ...{ background: colors[alert.color] } }}
        >
          <div style={{ flex: 2 }} id="msg">
            {ReactHtmlParser(alert.banner_text)}
          </div>
          <div
            style={styles.clickable}
            onClick={() =>
              this.setState(
                { showNotifcations: false },
                sessionStorage.setItem("showNotifcations", false)
              )
            }
          >
            <FaTimes />
          </div>
        </div>
      </IconContext.Provider>
    ) : null;
  }
}
