import React, { Component } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
  Col,
} from "reactstrap";

const styles = {
  mainSection: {
    margin: "36px 0px",
  },
  lessMargin: { margin: "4px 0px 0px 0px" },
  fontSource: { fontFamily: "Source Sans Pro" },
  fontMyriad: { fontFamily: "Myriad Pro" },
  bold: { fontWeight: "bold" },
  center: { textAlign: "center" },
  relative: {
    position: "relative",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#A90533",
    marginBottom: "4px",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "16px",
    height: "6em",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    fontWeight: "lighter",
    width: "100%",
  },
  cardImage: {
    height: "174px",
    backgroundSize: "cover",
  },
  altCard: {
    position: "inherit",
    height: "100%",
  },
  pointer: { cursor: "pointer" },
  typeButton: {
    padding: "2px 32px",
    borderRadius: "40px",
    border: "solid #117D91 2px",
    fontWeight: "bold",
    display: "inline",
    color: "#117D91",
    fontSize: "13px",
    marginRight: "5px",
    marginBottom: "2px",
    textTransform: "uppercase",
  },
  typeButtonVisitor: {
    border: "solid #52294E 2px",
    color: "#52294E",
    padding: "2px 8px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#5b5b5bdb",
    color: "white",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  col4: {
    padding: "0px 10px",
  },
};

export class AltCard extends Component {
  static displayName = AltCard.name;

  render() {
    const {
      shuttle_name,
      map_image,
      description,
      times,
      rate_fee,
      pdf_flyer,
      type,
      color,
    } = this.props.alt;
    const { update } = this.props;
    const showTruncate = description.length > 108;
    const description_truncated = showTruncate
      ? description.substr(0, 108)
      : description;

    return (
      <Col md={6} lg={4}>
        <Card className="alt-card" style={styles.altCard}>
          <header
            style={{ ...styles.relative, ...styles.pointer }}
            onClick={() => update({ crrAlt: this.props.alt })}
          >
            <CardImg
              className="alt-hover"
              top
              width="100%"
              style={{
                ...styles.cardImage,
                backgroundImage: `url(${map_image[0].url})`,
              }}
            />
            <div>
              <img
                style={styles.search}
                src="/resources/search-plus.png"
                alt=""
              />
              <CardTitle>Click to View</CardTitle>
            </div>
          </header>
          <CardBody className="d-flex flex-column">
            <CardTitle body style={styles.cardTitle}>
              {shuttle_name}
            </CardTitle>
            <div>
              {type.map((t) => (
                <div
                  style={{
                    ...styles.typeButton,
                    ...(t !== "Staff" ? styles.typeButtonVisitor : {}),
                  }}
                  size="sm"
                >
                  {t}
                </div>
              ))}
            </div>
            <CardTitle style={{ ...styles.subtitle, ...styles.lessMargin }}>
              {rate_fee}
            </CardTitle>
            <CardTitle
              style={{ ...styles.subtitle, ...{ marginBottom: "20px" } }}
            >
              {times}
            </CardTitle>
            <CardText style={styles.cardCopy}>
              {description_truncated + " "}
              {showTruncate ? (
                <a href={pdf_flyer.length !== 0 ? pdf_flyer[0].url : "#"}>
                  ...
                </a>
              ) : null}
            </CardText>
            <div className="mt-auto">
              <Button
                href={pdf_flyer.length !== 0 ? pdf_flyer[0].url : "#"}
                style={{
                  ...styles.cardButton,
                  ...(color ? { backgroundColor: color } : {}),
                }}
                target="_blank"
              >
                More Information
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
