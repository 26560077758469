import React, { Component } from "react";
import { Card, Button, CardTitle, CardText, CardBody } from "reactstrap";
import { Img } from "../atoms/Img";

const styles = {
  mainSection: {
    margin: "35px 0px",
  },
  lessMargin: { margin: "3px 0px 0px 0px" },
  fontSource: { fontFamily: "Source Sans Pro" },
  fontMyriad: { fontFamily: "Myriad Pro" },
  bold: { fontWeight: "bold" },
  center: { textAlign: "center" },
  relative: {
    position: "relative",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "0px",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#A90533",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
    height: "6em",
    overflowY: "hidden",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    fontWeight: "lighter",
    width: "100%",
  },
  cardImage: {
    backgroundSize: "cover",
  },
  altCard: {
    position: "inherit",
    height: "100%",
  },
  pointer: { cursor: "pointer" },
  typeButton: {
    padding: "4px 35px",
    borderRadius: 0,
    fontWeight: "bold",
    backgroundColor: "#117D91",
    display: "inline",
    color: "white",
    fontSize: "14px",
    marginRight: "5px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#5b5b5bdb",
    color: "white",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  col4: {
    padding: "0px 10px",
  },
  crop: {
    height: "225px",
    overflow: "hidden",
  },
};

export class TDMCard extends Component {
  static displayName = TDMCard.name;

  render() {
    const {
      title,
      main_text,
      header_image: [image],
      pdf: [file],
    } = this.props.card;
    //console.log({title,main_text, image, file})
    const showTruncate = main_text.length > 108;
    const main_text_truncated = showTruncate
      ? main_text.substr(0, 108)
      : main_text;

    return (
      <Card style={styles.altCard}>
        <div style={styles.crop}>
          <Img src={image.url} style={{ height: "200px" }} />
        </div>
        <CardBody className="d-flex flex-column">
          <CardTitle style={styles.cardTitle}>{title}</CardTitle>
          <CardTitle style={styles.subtitle}>
            Alternative Transportation Options
          </CardTitle>
          <CardText style={styles.cardCopy}>
            {main_text_truncated + " "}
            {showTruncate ? <a href={file ? file.url : "#"}>...</a> : null}
          </CardText>
          <div className="mt-auto">
            <Button
              style={styles.cardButton}
              href={file ? file.url : "#"}
              target="_blank"
            >
              Continue
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}
