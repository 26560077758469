import React, { Component } from "react";
import { Button } from "reactstrap";
import { insertIntoArray } from "../util";
import ReactHtmlParser from "react-html-parser";

const styles = {
  parkingHeader: {
    color: "#2e2d29",
    fontSize: "24px",
    fontWeight: "bold",
    margin: "0px",
  },
  parkingSubHeader: {
    marginBottom: "0px",
  },
  address: {
    fontWeight: "lighter",
    fontSize: "18px",
    color: "#2e2d29",
    marginRight: "1.5em",
  },
  link: {
    color: "#53284f",
    fontWeight: "bold",
    fontSize: "14px",
    textDecoration: "underline",
    lineHeight: "16px",
  },
  onOffSite: {
    padding: "2px 24px",
    borderRadius: "40px",
    fontWeight: "bold",
    display: "inline-block",
    border: "solid 2px",
    fontSize: "13px",
    marginTop: "16px",
    textTransform: "uppercase",
  },
  onSiteColor: {
    borderColor: "#80982a",
    color: "#80982a",
    textTransform: "uppercase",
  },
  offSiteColor: {
    borderColor: "#007d92",
    color: "#007d92",
  },
  parkingTypeHeader: {
    fontWeight: "bold",
    color: "#A70C36",
    fontSize: "14px",
    marginTop: "16px",
    marginBottom: "0px",
  },
  parkingTypes: {
    fontSize: "18px",
    fontWeight: "lighter",
    marginBottom: "0px",
  },
  getPermits: {
    fontSize: "18px",
    fontWeight: "lighter",
    backgroundColor: "#A70C36",
    borderColor: "#A70C36",
    padding: "0.5em 3em",
    //marginRight:"16px"
  },
  alternates: {
    lineHeight: 1,
    paddingTop: "5px",
  },
  flex: {
    display: "flex",
  },
  spaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottomButtons: {
    width: "100%",
    fontSize: "10px",
    fontWeight: "bold",
  },
  bottomButtonsContainer: {
    flex: 1,
  },
  pinIcon: {
    width: "12px",
    position: "relative",
    top: "-2px",
    left: "-2px",
  },
  topSpace: {
    marginTop: "16px",
  },
};

const shift_keys = {
  "Day Shift": "day_shift_permit",
  "Night Shift": "night_shift_permit",
  "Swing Shift": "swing_shift_permit",
};

export class ParkingItem extends Component {
  static displayName = ParkingItem.name;

  render() {
    const { parking, permits, shift, buildingAddress } = this.props;
    const permitType = shift_keys[shift];
    const parkingPermit = parking[permitType];
    // support data types: ["id"], "id", {id: ""}
    const permit = Array.isArray(parkingPermit)
      ? permits.find((p) => p.id === parkingPermit[0])
      : typeof parkingPermit === "string"
      ? permits.find((p) => p.id === parkingPermit)
      : parkingPermit;

    if (parking.on_campus_off_campus === null) return "";

    return (
      <div>
        <h3 style={styles.parkingHeader}>{parking.name}</h3>
        <p style={styles.parkingSubHeader}>
          <span style={styles.address}>{parking.address}</span>
          {parking.google_maps_url ? (
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={parking.google_maps_url}
                style={styles.link}
              >
                <img
                  style={styles.pinIcon}
                  src="/resources/map-pin-purple.svg"
                  alt=""
                />
                View on Google Maps
              </a>
            </span>
          ) : null}
        </p>

        <div
          style={{
            ...styles.onOffSite,
            ...(parking.on_campus_off_campus.toLowerCase() === "on-campus"
              ? styles.onSiteColor
              : styles.offSiteColor),
          }}
          size="sm"
        >
          {parking.on_campus_off_campus}
        </div>

        {parking.additional_parking_info ? (
          <div>
            <p style={styles.parkingTypeHeader}>PARKING INFORMATION:</p>
            <p style={styles.parkingTypes}>
              {ReactHtmlParser(parking.additional_parking_info)}
            </p>
          </div>
        ) : null}

        {parking.parking_cost ? (
          <div>
            <p style={styles.parkingTypeHeader}>PARKING COST:</p>
            <p style={styles.parkingTypes}>
              {ReactHtmlParser(parking.parking_cost)}
            </p>
          </div>
        ) : null}

        <div style={{ ...styles.flex, ...styles.spaceBetween }}>
          <div>
            <p style={styles.parkingTypeHeader}>PARKING TYPE:</p>
            <p style={styles.parkingTypes}>
              {insertIntoArray(parking.permit_type, <br />)}
            </p>
          </div>
          <div>
            {!!permit && (
              <Button
                color="primary"
                target="_blank"
                style={styles.getPermits}
                href={permit.permit_link}
              >
                Get Permit
              </Button>
            )}
          </div>
        </div>

        <div
          style={{ ...styles.flex, ...styles.spaceBetween, ...styles.topSpace }}
        >
          <div style={styles.bottomButtonsContainer}>
            <Button
              style={{ ...styles.bottomButtons, padding: "10px 20px" }}
              color="secondary"
              className="lower-parking-buttons"
              href={`/Shuttles/?lotKey=${parking.id}`}
            >
              SHUTTLE ROUTES
            </Button>
          </div>
          <div
            style={{
              ...styles.bottomButtonsContainer,
              ...{ paddingLeft: "5px" },
            }}
          >
            <Button
              style={{ ...styles.bottomButtons, padding: "10px 20px" }}
              color="secondary"
              className="lower-parking-buttons alts"
              href="/Alternatives"
            >
              ALTERNATIVE TRANSPORTATION
            </Button>
          </div>
        </div>

        <div
          style={{
            ...styles.flex,
            ...styles.spaceBetween,
            ...{ marginTop: "8px" },
          }}
        >
          <div style={styles.bottomButtonsContainer}>
            <Button
              target="_blank"
              style={{ ...styles.bottomButtons, padding: "10px 20px" }}
              color="secondary"
              className="lower-parking-buttons"
              href={`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                parking.address
              )}&destination=${encodeURIComponent(
                buildingAddress.trim()
              )}&travelmode=walking`}
            >
              WALKING DIRECTIONS
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
