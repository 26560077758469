import React, { Component } from "react";
import { Error } from "../components/Error";

console.log(Error);
export const ErrorHOC = (Page, type) =>
  class extends Component {
    static displayName = ErrorHOC.name;

    constructor() {
      super();
      this.state = {
        err: null,
      };
      this.onError = this.onError.bind(this);
    }

    onError(msg) {
      this.setState({ err: msg });
    }

    render() {
      const { err } = this.state;
      return err ? (
        <Error msg={err} />
      ) : (
        <div>
          <Page type={type} onError={this.onError} />
        </div>
      );
    }
  };
