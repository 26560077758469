import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Row, Col, Container, Jumbotron } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import "./css/Announcement.css";
import { fridge } from "../utils/FridgeClient";

const styles = {
  center: { textAlign: "center" },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "#A90533",
    width: "100%",
  },
  cardBody: {
    padding: "36px",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  rc: {
    borderRadius: "5px",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #9C9C9C",
  },
  inputSpace: {
    marginBottom: "1.5em",
  },
  mainSpace: {
    padding: "36px",
  },
};
const typeMap = {
  paragraph_heading: ({ heading, paragraph }) => (
    <div style={styles.mainSection}>
      <h4>{heading}</h4>
      {ReactHtmlParser(paragraph)}
    </div>
  ),
  paragraph: ({ paragraph }) => ReactHtmlParser(paragraph),
  image: ({ image, image_max_width: maxWidth }) => (
    <img
      style={{ maxWidth, ...styles.mainSection }}
      src={image[0].url}
      alt=""
    />
  ),
  alternate_section: ({ paragraph, section_heading }) => (
    <Jumbotron style={{ ...{ padding: "2em" }, ...styles.mainSection }}>
      <h4 style={styles.altHeader}>{section_heading}</h4>
      {ReactHtmlParser(paragraph)}
    </Jumbotron>
  ),
};

const sectionMapper = (section) => typeMap[section.type](section);

export class Announcement extends Component {
  static displayName = Announcement.name;
  constructor() {
    super();
    this.state = { page: null };
  }

  async componentDidMount() {
    //const urlParams = new URLSearchParams(window.location.search);
    //const announcementKey = urlParams.get('announcementKey');
    //console.log({announcementKey})

    const announcementsJson = await fridge.get(
      `/content/covid19_announcement_page`
    );

    this.setState({ page: announcementsJson });
  }
  render() {
    const { page } = this.state;
    console.log({ page });
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}>
          <Header />
          <SubHeader actives={{ contact: false }} />

          {page ? (
            <Container>
              <Row>
                <Col style={{ marginTop: "32px" }} fluid>
                  <h3 style={{ ...styles.center, ...{ fontWeight: "bold" } }}>
                    {page.page_title}
                  </h3>
                </Col>
              </Row>
            </Container>
          ) : null}

          {page ? (
            <Container style={{ marginBottom: "32px", marginTop: "32px" }}>
              <Row>
                <Col fluid>{page.content.map(sectionMapper)}</Col>
              </Row>
            </Container>
          ) : null}
        </div>

        <div style={{ flexShrink: 0 }}>
          <Footer />
        </div>
      </div>
    );
  }
}
