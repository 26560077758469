import React, { Component } from "react";

const widths = 128;
const styles = {
  image: {
    marginRight: "3%",
    minWidth: "0px",
    minHeight: "0px",
    objectFit: "contain",
    flex: 1,
  },
  headerRight: {
    position: "absolute",
    right: "10%",
  },

  mainHeader: {
    display: "flex",
    width: "100%",
    padding: "30px 0px",
  },
};
export class Header extends Component {
  static displayName = Header.name;

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { width } = this.state;
    return (
      <div
        className="safari_only"
        style={{
          justifyContent: "center",
          display: "flex",
          padding: "16px 0px",
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            style={{
              ...styles.image,
              width: widths,
              ...(width > 768 ? {} : { marginLeft: "0px" }),
            }}
            alt=""
            src="/resources/sm-logo.svg"
          />
          <img
            style={{ ...styles.image, width: widths }}
            alt=""
            src="/resources/shc-logo.svg"
          />
          <img
            style={{ ...styles.image, width: widths }}
            alt=""
            src="/resources/lpch-logo.svg"
          />
        </div>
      </div>
    );
  }
}
