import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Home } from "./components/Home";
import { Staff } from "./components/Staff";
import { Shuttles } from "./components/Shuttles";
import { Alternatives } from "./components/Alternatives";
import { Contact } from "./components/Contact";
import { Announcements } from "./components/Announcements";
import { MapsAndResources } from "./components/MapsAndResources";
import { Newsletters } from "./components/Newsletters";
import { ErrorHOC } from "./atoms/ErrorHOC";
import { Announcement } from "./components/Annoucement";
import { VisitorLPCH } from "./molecules/VisitorParking_LPCH";
import { VisitorSHC } from "./molecules/VisitorParking_SHC";
import { Error } from "./components/Error";
import { NewEmployee } from "./components/NewEmployee";
import { Faqs } from "./components/Faqs";
import { fridge } from "./utils/FridgeClient";

import "./custom.css";

console.log(ErrorHOC);
const HomePage = ErrorHOC(Home);
const StaffPage = ErrorHOC(Staff);
const Shuttlesage = ErrorHOC(Shuttles);
const AlternativesPage = ErrorHOC(Alternatives);
const ContactPage = ErrorHOC(Contact);
const AnnouncementsPage = ErrorHOC(Announcements);
const visitorLpchPage = ErrorHOC(VisitorLPCH);
const visitorShcPage = ErrorHOC(VisitorSHC);
const MapsandresourcesPage = ErrorHOC(MapsAndResources);
const NewslettersPage = ErrorHOC(Newsletters);
const NewEmployeePage = ErrorHOC(NewEmployee);
const FaqPage = ErrorHOC(Faqs);
const ErrorPage = Error;

export default class App extends Component {
  static displayName = App.name;

  async componentDidMount() {
    const pageRes = await fridge.get("/content/transportation_services_code");
    const file = pageRes.file;
    const url = file.length === 1 ? file[0].url : "#";

    localStorage.setItem("servicesCodes", url);
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/Parking" component={StaffPage} />
          <Route path="/Shuttles" component={Shuttlesage} />
          <Route path="/Alternatives" component={AlternativesPage} />
          <Route path="/Contact" component={ContactPage} />
          <Route path="/Announcements" component={AnnouncementsPage} />
          <Route path="/mapsandresources" component={MapsandresourcesPage} />
          <Route path="/newsletters" component={NewslettersPage} />
          <Route path="/Announcement" component={Announcement} />
          <Route path="/COVID19" component={Announcement} />
          <Route path="/LPCHPatientVisitor" component={visitorLpchPage} />
          <Route path="/SHCPatientVisitor" component={visitorShcPage} />
          <Route path="/newemployees" component={NewEmployeePage} />
          <Route path="/Faq" component={FaqPage} />
          <Route path="/Error" component={ErrorPage} />
          <Route status={404}>
            <Error msg="404 Not found." />
          </Route>
        </Switch>
      </div>
    );
  }
}
