import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { BlockGrid } from "../atoms/BlockGrid";
import { Img } from "../atoms/Img";
import { Footer } from "../atoms/Footer";
import { Hero } from "../atoms/Hero";
import {
  Container,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  CardImg,
} from "reactstrap";
import { isEmpty, isNil } from "ramda";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { fridge } from "../utils/FridgeClient";

const colors = {
  Blue: "#279AB9",
  "Stanford Red": "#A90533",
  "Light Grey": "#DDDDDD",
};

/* const createSlide = (img) => (
  <div>
    <div
      style={{
        backgroundImage: `url(${img.url})`,
        height: "87px",
        width: "100%",

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>
  </div>
); */

const styles = {
  mainSection: {
    margin: "36px 0px",
  },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    width: "100%",
    fontSize: "12px",
    padding: "10px",
    marginBottom: "4px",
  },
  crop: {
    overflow: "hidden",
  },
};
const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

const renderButtons = (buttons) => (
  <div className="mt-auto">
    {buttons.map((b) =>
      b.type === "pdf_button" ? (
        <Button
          href={
            isNilOrEmpty(b.pdf_or_document_file)
              ? "#"
              : b.pdf_or_document_file[0].url
          }
          style={{
            ...styles.cardButton,
            backgroundColor: colors[b.button_color],
          }}
        >
          {b.button_text}
        </Button>
      ) : (
        <Button
          target={b.open_in_new_window ? "_blank" : "_self"}
          href={b.button_url}
          style={{ background: colors[b.button_color], ...styles.cardButton }}
        >
          {b.button_text}
        </Button>
      )
    )}
  </div>
);

const GenericCard = (props) => (
  <Card>
    {isNilOrEmpty(props.image) ? null : (
      <div style={styles.crop}>
        <Img src={props.image[0].url} style={{ height: "200px" }} />
      </div>
    )}
    <CardBody>
      <CardTitle style={{ ...styles.cardTitle, fontSize: "16px" }}>
        {props.name}
      </CardTitle>
      <CardText></CardText>
      {renderButtons(props.buttons)}
    </CardBody>
  </Card>
);

export class Home extends Component {
  static displayName = Home.name;
  constructor() {
    super();
    this.state = {
      mainSection: "",
      home: null,
    };
  }
  async componentDidMount() {
    const pageJson = await fridge.get("/content/home");

    if (pageJson.error) {
      this.props.onError(
        <p>
          {pageJson.error.code} {pageJson.error.message}
        </p>
      );
    }

    this.setState({ mainSection: pageJson.main_section_text, home: pageJson });
  }

  render() {
    const { mainSection, home } = this.state;
    //const preloadedSlides = { url: "/resources/hero/DSC0191.png" };
    return home ? (
      <div>
        <Header />
        <Hero img="" />
        <div style={{ height: 56, background: colors["Stanford Red"] }}></div>
        {/*<Slider
          fade={true}
          speed={0}
          arrows={true}
          autoplaySpeed={5000}
          autoplay={true}
        >
          {home? home.header_images.map(createSlide) : createSlide(preloadedSlides)}
        </Slider>*/}

        <Container>
          <Row>
            <Col style={styles.mainSection}>
              <div
                id="mainSection"
                style={styles.mainCopy}
                dangerouslySetInnerHTML={{ __html: mainSection }}
              />

              <Row style={styles.mainSection}>
                <Col sm="12" md={{ size: 4, offset: 1 }}>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src={home.patient_and_visitor_image[0].url}
                      alt="Card image cap"
                    />

                    <CardBody>
                      <CardTitle style={styles.cardTitle}>
                        PATIENT AND VISITOR
                      </CardTitle>
                      <CardText style={styles.cardCopy}>
                        Transportation options.
                      </CardText>

                      <Button
                        style={styles.cardButton}
                        href="SHCPatientVisitor"
                      >
                        Stanford Health Care
                      </Button>

                      {/*<Button style={styles.cardButton} href="https://stanfordhealthcare.org/for-patients-visitors/locations-and-parking.html">Stanford Health Care</Button>*/}
                      {/*<Button style={{...styles.cardButton, ...{  padding:"10px", background:"#279AB9"}}} href="https://www.stanfordchildrens.org/en/location/lucile-packard-childrens-hospital">Lucile Packard Children's Hospital</Button> */}

                      <Button
                        style={{
                          ...styles.cardButton,
                          ...{ padding: "10px", background: "#279AB9" },
                        }}
                        href="LPCHPatientVisitor"
                      >
                        Lucile Packard Children's Hospital
                      </Button>
                      <Button
                        style={{
                          ...styles.cardButton,
                          ...{ padding: "10px", background: "#DDDDDD" },
                        }}
                        href="https://visit.stanford.edu/index.html"
                      >
                        School of Medicine
                      </Button>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="12" md={{ size: 4, offset: 1 }}>
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      src={home.staff_image[0].url}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle style={styles.cardTitle}>STAFF</CardTitle>
                      <CardText style={styles.cardCopy}>
                        Transportation options.
                      </CardText>
                      <Button href="/Alternatives" style={styles.cardButton}>
                        Stanford Health Care
                      </Button>
                      <Button
                        style={{
                          ...styles.cardButton,
                          ...{
                            fontSize: "12px",
                            padding: "10px",
                            background: "#279AB9",
                          },
                        }}
                        href="/Alternatives"
                      >
                        Lucile Packard Children’s Hospital
                      </Button>
                      <Button
                        style={{
                          ...styles.cardButton,
                          ...{
                            fontSize: "12px",
                            padding: "10px",
                            background: "#DDDDDD",
                          },
                        }}
                        href="https://transportation.stanford.edu/parking/purchase-a-parking-permit"
                      >
                        School of Medicine
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <BlockGrid
                list={home.user_groups.map((c) => (
                  <Col md={6} lg={4}>
                    <GenericCard
                      buttons={c.buttons}
                      image={c.image}
                      name={c.name}
                      button_link={c.button_link}
                      pdf_flyer={c.pdf_flyer}
                    />
                  </Col>
                ))}
              />
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    ) : null;
  }
}
