import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Container, Row, Col } from "reactstrap";
import { BlockGrid } from "../atoms/BlockGrid";
import { AltCard } from "../atoms/AltCard";
import { Alert } from "../atoms/Alert/Alert";

import Select from "react-dropdown-select";
import { isNil, isEmpty, propEq, either, assoc, uniqBy, prop } from "ramda";
import { fridge } from "../utils/FridgeClient";

const isNilOrEmpty = either(isEmpty, isNil);

const styles = {
  mainSection: {
    marginTop: "36px",
    marginBottom: "36px",
  },
  searchSection: {
    marginBottom: "36px",
  },
  fontSource: { fontFamily: "Source Sans Pro" },
  fontMyriad: { fontFamily: "Myriad Pro" },
  bold: { fontWeight: "bold" },
  center: { textAlign: "center" },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "0px",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#A90533",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    boarderColor: "#A90533",
    fontWeight: "lighter",
    width: "100%",
  },
  parkingHeader: {
    backgroundColor: "#A70C36",
    height: "3em",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "3em",
  },
  parkingText: {
    margin: "0px",
    fontWeight: "bold",
  },
  parkingMain: {
    paddingLeft: "3em",
    paddingTop: "1em",
  },
  parkingMsg: {
    color: "#585754",
    fontSize: "14px",
  },
  parkingBuilding: {
    position: "relative",
    top: "-10px",
  },
  spacerBottom: {
    marginBottom: "16px",
  },
  spacerTop: {
    marginTop: "36px",
  },
  parkingOptions: {
    maxHeight: "30em",
    minHeight: "30em",
    overflowY: "scroll",
  },
  noPadding: {
    padding: "0px",
  },
};

export class Shuttles extends Component {
  static displayName = Shuttles.name;
  constructor() {
    super();
    this.state = {
      crrAlts: null,
      alts: null,
      lots: [],
      crrLot: null,
      headerImage: null,
      time: "",
      tdms: null,
    };
    this.isErrored = this.isErrored.bind(this);
    this.update = this.update.bind(this);
  }
  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }
  update(obj) {
    window.scrollTo({ top: this.ref.offsetHeight, behavior: "smooth" });
    this.setState(obj);
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const lotKey = urlParams.get("lotKey");

    const json = await fridge.get("/content/shuttles?sort_method=shuttle_name");
    this.isErrored(json);

    const lotJson = await fridge.get("/content/parking_lots_and_garages");
    this.isErrored(lotJson);

    const buildingsJson = await fridge.get("/content/buildings");
    this.isErrored(buildingsJson);

    const crrAlts = !isNilOrEmpty(lotKey)
      ? lotJson.find(propEq("id", lotKey)).shuttle_routes
      : null;

    const pageJson = await fridge.get("/content/shuttles_page");
    this.isErrored = this.isErrored.bind(pageJson);

    this.setState({
      alts: json,
      lots: lotJson,
      buildings: buildingsJson.map((b) => assoc("name", b.building_name, b)),
      headerImage: pageJson.header_image,
      crrLot: lotJson.find((l) => l.id === lotKey) || {},
      crrAlts,
    });
  }

  render() {
    const { alts, crrAlts, lots, headerImage, crrLot, buildings } = this.state;
    return alts && headerImage && lots ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <Hero img={headerImage ? headerImage[0].url : null} />
        <SubHeader actives={{ shuttles: true }} />

        <Container style={styles.mainSection}>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <Row>
                <Col md={3} className="d-none d-md-flex align-items-center">
                  <p
                    style={{
                      margin: "0px",
                      fontStyle: "italic",
                      color: "grey",
                    }}
                  >
                    Show shuttles that service:
                  </p>
                </Col>
                <Col md={{ size: 9, offset: 0 }} sm={{ size: 8, offset: 2 }}>
                  <Select
                    values={[crrLot]}
                    options={uniqBy(prop("name"), [...lots, ...buildings])}
                    labelField={"name"}
                    searchBy={"name"}
                    multi={false}
                    clearable={true}
                    dropdownHandle={true}
                    onChange={(lot) => {
                      return !isEmpty(lot)
                        ? this.setState({ crrAlts: lot[0].shuttle_routes })
                        : null;
                    }}
                    onClearAll={() => this.setState({ crrAlts: null })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {isEmpty(crrAlts) ? (
          <Row>
            <Col
              sm={12}
              className="d-sm-flex align-items-center justify-content-center"
            >
              <p style={styles.mainSection}>
                Looks like there are no shuttles that service this lot.
              </p>
            </Col>
          </Row>
        ) : (
          <BlockGrid
            list={
              crrAlts
                ? crrAlts.map((a) => <AltCard alt={a} />)
                : alts.map((a) => <AltCard alt={a} />)
            }
          />
        )}

        <Footer />
      </div>
    ) : null;
  }
}
