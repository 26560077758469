import React, { Component } from 'react';
import './radio.css'

export class Radio extends Component {
  static displayName = Radio.name;

  render () {
    const { onClick, label, checked, name, value, style} = this.props
    return (
      <label class="radio-container" style={style}>{label}
        <input type="radio" value={value}  name={name} onClick={onClick} checked={checked}/>
        <span class="checkmark"></span>
      </label> 

    );
  }
}
