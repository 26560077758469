import React, { Component } from "react";
import { isEmpty, isNil } from "ramda";
import { fridge } from "../utils/FridgeClient";

const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

export class Hero extends Component {
  static displayName = Hero.name;

  constructor() {
    super();
    this.state = {
      json: null,
    };
  }

  async componentDidMount() {
    const pageJson = await fridge.get("/content/header");

    this.setState({ json: pageJson });
  }

  render() {
    const { img } = this.props;
    const { json } = this.state;
    /*     const heroStyle = {
      // backgroundImage: `url("${img}")`,
      // backgroundImage: `url("https://assets.fridgecms.com/9f71ea7b-9912-4e57-8f40-eb0ee773f631/DaVHrkYx5YPGEp_Dv6om6/-4125546798834411387complete_edit-01.svg")`,
      backgroundColor: "#cccccc",
      height: "170px",
      backgroundPosition: "bottom center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }; */
    return json ? (
      <div>
        {" "}
        <img
          src={isNilOrEmpty(json.header[0]) ? "" : json.header[0].url}
          alt=""
        />
      </div>
    ) : null;
  }
}
