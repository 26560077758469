import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Img } from "../atoms/Img";
import { BlockGrid } from "../atoms/BlockGrid";
import { Footer } from "../atoms/Footer";
import { Alert } from "../atoms/Alert/Alert";
import {
  Row,
  Col,
  Container,
  Jumbotron,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { isEmpty, isNil } from "ramda";
import ReactHtmlParser from "react-html-parser";
import { fridge } from "../utils/FridgeClient";

const styles = {
  mainSection: {
    margin: "36px 0px 0px",
  },
  altHeader: {
    color: "#A80534",
  },
  center: { textAlign: "center" },
  cardSection: {
    margin: "16px 0px",
  },
  mainImg: {
    width: "100%",
  },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    width: "100%",
    fontSize: "12px",
    color: "white",
  },
  onOffSite: {
    padding: "4px 24px",
    borderRadius: 0,
    fontWeight: "bold",
  },
};

const typeMap = {
  paragraph_heading: ({ heading, paragraph }) => (
    <div style={styles.mainSection}>
      <h4>{heading}</h4>
      {ReactHtmlParser(paragraph)}
    </div>
  ),
  paragraph: ({ paragraph }) => ReactHtmlParser(paragraph),
  image: ({ image, image_max_width: maxWidth }) => (
    <img
      style={{ maxWidth, ...styles.mainSection }}
      src={image[0].url}
      alt=""
    />
  ),
  alternate_section: ({ paragraph, section_heading }) => (
    <Jumbotron style={{ ...{ padding: "2em" }, ...styles.mainSection }}>
      <h4 style={styles.altHeader}>{section_heading}</h4>
      {ReactHtmlParser(paragraph)}
    </Jumbotron>
  ),
};

const isNilOrEmpty = (x) => isEmpty(x) || isNil(x);

const MapCard = (props) => (
  <Card style={{ height: "100%" }}>
    {isNilOrEmpty(props.image) ? null : (
      <div style={styles.crop}>
        <Img src={props.image[0].url} style={{ height: "200px" }} />
      </div>
    )}
    <CardBody>
      <CardTitle style={styles.cardTitle}>{props.name}</CardTitle>
      <CardText style={{ height: "4em" }}>{props.text}</CardText>
      <div
        style={{ position: "absolute", bottom: "22px", width: "85%" }}
        className="mt-auto"
      >
        <Button style={styles.cardButton} href={props.pdf_flyer[0].url}>
          Continue
        </Button>
      </div>
    </CardBody>
  </Card>
);

export class MapsAndResources extends Component {
  constructor() {
    super();
    this.state = { page: null };
    this.isErrored = this.isErrored.bind(this);
  }

  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }

  async componentDidMount() {
    const json = await fridge.get("/content/announcements_page");
    this.isErrored(json);

    const resourcesJson = await fridge.get("/content/maps_and_resources");
    this.isErrored(resourcesJson);

    this.setState({ page: json, resources: resourcesJson });
  }

  render() {
    const { page, resources } = this.state;
    console.log(typeMap);
    return page ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <Hero img={page.header_image ? page.header_image[0].url : null} />
        <SubHeader actives={{ quicklinks: true }} />

        <Container>
          <Row>
            <Col style={{ marginTop: "32px" }} fluid>
              <h3 style={{ ...styles.center, ...{ fontWeight: "bold" } }}>
                Maps and Resources
              </h3>
            </Col>
          </Row>
        </Container>

        <Container style={{ marginBottom: "32px" }}>
          <Row>
            <Col fluid>
              <BlockGrid
                list={resources.map((c) => (
                  <Col md={6} lg={4}>
                    <MapCard
                      announcement={c.id}
                      image={c.image}
                      name={c.name}
                      text={c.date}
                      pdf_flyer={c.file}
                    />
                  </Col>
                ))}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    ) : null;
  }
}
