import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Alert } from "../atoms/Alert/Alert";
import { Row, Col, Container } from "reactstrap";
import { fridge } from "../utils/FridgeClient";

const styles = {
  mainSection: {
    margin: "36px 0px 0px",
  },
  altHeader: {
    color: "#A80534",
  },
  center: { textAlign: "center" },
  cardSection: {
    margin: "16px 0px",
  },
  mainImg: {
    width: "100%",
  },
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "none",
    width: "100%",
    fontSize: "12px",
    color: "white",
  },
  onOffSite: {
    padding: "4px 24px",
    borderRadius: 0,
    fontWeight: "bold",
  },
  faqQuestion: {
    backgroundColor: "#fff",
    border: "solid 4px",
    borderRadius: 18,
    borderColor: "#DDDDDD",
    padding: "24px",
    marginTop: "24px",
    fontSize: "18px",
    fontWeight: "bold",
    filter: "drop-shadow(-8px 8px 8px rgb(0 0 0 / 0.3))",
  },
  faqAnswerOpen: {
    border: "solid 4px",
    borderRadius: 18,
    borderColor: "#DDDDDD",
    padding: "24px",
    marginTop: "12px",
    height: "auto",
    visibility: "visible",
    opacity: "1",
    WebkitTransition: "all .2s ease",
    MozTransition: "all .2s ease",
    msTransition: "all .2s ease",
    OTransition: "all .2s ease",
    transition: "all .2s ease",
  },
  faqAnswerClosed: {
    border: "solid 4px",
    borderRadius: 18,
    borderColor: "#DDDDDD",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "24px",
    paddingRight: "24px",
    marginTop: "0px",
    height: "0px",
    visibility: "hidden",
    opacity: "0",
    WebkitTransition: "all .2s ease",
    MozTransition: "all .2s ease",
    msTransition: "all .2s ease",
    OTransition: "all .2s ease",
    transition: "all .2s ease",
  },
};

export class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = { page: null, showAnswer: false, setId: null };
    this.isErrored = this.isErrored.bind(this);
  }

  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }

  async componentDidMount() {
    const json = await fridge.get("/content/faqs");
    this.isErrored(json);

    this.setState({ page: json });
  }

  render() {
    const { page, showAnswer, setId } = this.state;

    return page ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <SubHeader actives={{ quicklinks: true }} />
        <div
          style={{
            backgroundImage: "url(" + "/resources/faq-bg-bike.svg" + ")",
            backgroundSize: "contain",
            backgroundAttachment: "local, scroll",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "250px",
            left: "-10%",
            opacity: "20%",
            height: "45%",
            width: "45%",
            maxWidth: "400px",
            zIndex: "-1000000",
          }}
        />
        <div
          style={{
            backgroundImage: "url(" + "/resources/faq-bg-car.svg" + ")",
            backgroundSize: "contain",
            backgroundAttachment: "local, scroll",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "100px",
            right: "-10%",
            opacity: "20%",
            height: "45%",
            width: "45%",
            maxWidth: "400px",
            zIndex: "-1000000",
          }}
        />
        <Container>
          <Row>
            <Col style={{ marginTop: "32px" }} fluid>
              <h3 style={{ ...styles.center, ...{ fontWeight: "bold" } }}>
                FAQ
              </h3>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginBottom: "32px" }}>
          {page.map((item) => (
            <Col>
              <Row
                style={{ ...styles.faqQuestion }}
                onClick={() =>
                  this.setState({ showAnswer: !showAnswer, setId: item.id })
                }
                className="faq-question"
              >
                {item.question_image[0] ? (
                  <>
                    <Col xs="auto" style={{ alignSelf: "center" }}>
                      <img
                        src={item.question_image[0].url}
                        alt={item.question_image[0].name}
                        style={{
                          maxHeight: "40px",
                          minWidth: "100px",
                          maxWidth: "100px",
                          display: "flex-initial",
                        }}
                      />
                    </Col>
                    <Col>{item.question}</Col>
                  </>
                ) : (
                  <>{item.question}</>
                )}
              </Row>
              <Row
                style={
                  setId === item.id && showAnswer
                    ? { ...styles.faqAnswerOpen }
                    : { ...styles.faqAnswerClosed }
                }
              >
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Row>
            </Col>
          ))}
        </Container>
        <Footer />
      </div>
    ) : null;
  }
}
