import React, { Component } from "react";
import {
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from "reactstrap";

const styles = {
  breadCrumbs: {
    padding: "7px 35px",
  },
  crumb: {
    fontSize: "16px",
    fontFamily: "Source Sans Pro",
    color: "white",
    fontWeight: "bold",
  },
  seperator: {
    margin: "0px 5px",
  },
  navLinks: {
    color: "white",
    textTransform: "uppercase",
  },
  navItems: {
    borderLeft: "#000000 solid 1px",
    borderRight: "#000000 solid 1px",
    marginRight: "-1px",
    padding: "0.5em",
  },
  navItemLast: {
    borderRight: "#000000 solid 1px",
  },
  navBar: {
    justifyContent: "center",
    padding: "0em 1em",
  },
};

export class SubHeader2 extends Component {
  static displayName = SubHeader2.name;
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  render() {
    const { color, entries, root } = this.props;
    const { open } = this.state;
    return (
      <div style={{ backgroundColor: color }}>
        <Navbar
          color={color ? color : "#A70C36"}
          style={styles.navBar}
          expand="md"
        >
          <NavbarToggler onClick={() => this.setState({ open: !open })} />
          <Collapse style={{ justifyContent: "center" }} isOpen={open} navbar>
            <Nav navbar>
              {entries.map((e) => (
                <NavItem style={styles.navItems}>
                  <NavLink
                    style={styles.navLinks}
                    href={`${root}#${e.replace(/ /g, "")}`}
                  >
                    {e}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
