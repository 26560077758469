import React, { Component } from "react";
import { Header } from "../atoms/Header";
import { Hero } from "../atoms/Hero";
import { SubHeader } from "../atoms/SubHeader";
import { Footer } from "../atoms/Footer";
import { Alert } from "../atoms/Alert/Alert";
import {
  Col,
  Button,
  Input,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Jumbotron,
  Container,
  Row,
} from "reactstrap";
import * as R from "ramda";
import { AsYouType } from "libphonenumber-js";
import { fridge } from "../utils/FridgeClient";
import ReactHtmlParser from "react-html-parser";

import "./css/Contact.css";

const styles = {
  mainCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "24px",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  cardCopy: {
    color: "#2e2d29",
    fontWeight: "lighter",
    fontSize: "18px",
  },
  cardButton: {
    backgroundColor: "#A90533",
    border: "#A90533",
    width: "100%",
  },
  cardBody: {
    padding: "36px",
  },
  search: {
    position: "relative",
    top: "-5px",
    left: "-15px",
  },
  rc: {
    borderRadius: "5px",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #9C9C9C",
  },
  inputSpace: {
    marginBottom: "1.5em",
  },
  mainSpace: {
    padding: "36px",
  },
};
const isNotNilOrEmpty = R.complement(R.either(R.isNil, R.isEmpty));

const validateForm = R.where({
  name: isNotNilOrEmpty,
  email: isNotNilOrEmpty,
  phone: isNotNilOrEmpty,
  question: isNotNilOrEmpty,
});

const typeMap = {
  paragraph_heading: ({ heading, paragraph }) => (
    <div style={styles.mainSection}>
      <h4>{heading}</h4>
      {ReactHtmlParser(paragraph)}
    </div>
  ),
  paragraph: ({ paragraph }) => ReactHtmlParser(paragraph),
  image: ({ image, image_max_width: maxWidth }) => (
    <img
      style={{ maxWidth, ...styles.mainSection }}
      src={image[0].url}
      alt=""
    />
  ),
  alternate_section: ({ paragraph, section_heading }) => (
    <Jumbotron style={{ ...{ padding: "2em" }, ...styles.mainSection }}>
      <h4 style={styles.altHeader}>{section_heading}</h4>
      {ReactHtmlParser(paragraph)}
    </Jumbotron>
  ),
};

const sectionMapper = (section) => typeMap[section.type](section);

export class Contact extends Component {
  static displayName = Contact.name;
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      question: "",
      modalContent: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.isErrored = this.isErrored.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
  }
  isErrored(json) {
    if (json.error) {
      this.props.onError(
        <p>
          {json.error.code} {json.error.message}
        </p>
      );
    }
  }

  handleChangePhone(e) {
    const formatted = new AsYouType("US").input(e.target.value);
    console.log({ formatted });
    this.setState({ [e.target.name]: formatted });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async submit(e) {
    e.preventDefault();
    if (!validateForm(this.state)) {
      alert("All fields are required. Please go back and fill in all fields");
      return;
    }

    const {
      headerImage,
      modalContent,
      modalHeader,
      ...stateWithoutHeaderImage
    } = this.state;
    const payload = {
      ...stateWithoutHeaderImage,
      date: new Date().toISOString(),
      active: true,
    };
    const res = await fridge.post("/public/contact_form", payload);

    if (res.error === undefined) {
      this.setState({
        modalContent:
          "Thank you for contacting us! We will get back to you as soon as possible",
        modalHeader: "Message received",
      });
    } else {
      this.setState({
        modalContent: `Uh oh... there was an errro\n\n${res.error}`,
        modalHeader: "There was a problem",
      });
    }
  }

  async componentDidMount() {
    const pageJson = await fridge.get("/content/contact");
    this.isErrored(pageJson);

    this.setState({ page: pageJson, headerImage: pageJson.header_image });
  }

  render() {
    const { headerImage, modalContent, modalHeader, phone, page } = this.state;
    return headerImage ? (
      <div>
        <Alert type="alert_banner_staff" />
        <Header />
        <Hero img={headerImage ? headerImage[0].url : null} />
        <SubHeader actives={{ contact: true }} />

        <div style={{ ...styles.main, ...styles.mainSpace }}>
          <div style={styles.main}>
            <p style={styles.cardTitle}>Contact</p>
            <div>
              {page ? (
                <Container
                  style={{
                    marginBottom: "32px",
                    marginTop: "16px",
                  }}
                >
                  <Row>
                    <Col fluid style={styles.p}>
                      {page.content.map(sectionMapper)}
                    </Col>
                  </Row>
                </Container>
              ) : null}
            </div>
          </div>
          <Col md={6}>
            <Card style={styles.cardBody}>
              <form onSubmit={this.submit}>
                <Input
                  className="sm-placeholder"
                  style={{ ...styles.input, ...styles.inputSpace }}
                  placeholder="Name"
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                />
                <Input
                  className="sm-placeholder"
                  style={{ ...styles.input, ...styles.inputSpace }}
                  placeholder="Email"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                />
                <Input
                  className="sm-placeholder"
                  style={{ ...styles.input, ...styles.inputSpace }}
                  placeholder="Phone"
                  type="tel"
                  name="phone"
                  onChange={this.handleChangePhone}
                  value={phone}
                />
                <Input
                  className="sm-placeholder"
                  style={{ ...styles.input, ...styles.inputSpace }}
                  placeholder="Question"
                  type="textarea"
                  rows={5}
                  name="question"
                  onChange={this.handleChange}
                />
                <Button style={styles.cardButton}>Submit</Button>
              </form>
            </Card>
          </Col>
          <Modal isOpen={modalContent} centered={true}>
            <ModalHeader style={styles.cardTitle}>{modalHeader}</ModalHeader>
            <ModalBody style={styles.mainCopy}>{modalContent}</ModalBody>
            <ModalFooter>
              <Button
                style={styles.cardButton}
                onClick={() => this.setState({ modalContent: null })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <Footer />
      </div>
    ) : null;
  }
}
